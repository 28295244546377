import React, { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { WebService } from "../../Services/WebService";
import { InputText } from "../Form";
import { useDispatch } from "react-redux";
import TableComponent, { NoRecordTemplate } from "../../Services/TableComponent";
import { StandardConst } from "../../Services/StandardConst";
import ActionButton from "../../Services/ActionButton";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";

const { forwardRef, useState, useImperativeHandle } = React;

const LoginByCompanyName = ({ origin }, ref) => {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [searchContent, setSearchContent] = useState("");
    const [searchedCompanyData, setSearchedCompanyData] = useState([]);
    const [userType, setUserType] = useState(null);
    const navigate=useNavigate();

    useEffect(() => {setUserType(null)},[])

    useImperativeHandle(ref, () => ({
        openModal: async (id) => {
            setUserType(null);
        setShow(true);
        },
    }));

    const handleUserSelection = (type) => {
        if (type === "no_company") {
            navigate('/nonCompanyUserLogin') // Redirect if no company
        } else {
            setUserType(type); // Show search input if Employee or Customer
        }
    };

    const handleClose = () => {
        setShow(false);
        setSearchedCompanyData([]);
    };

    const searchCompany = async () => {
        if(searchContent){
            await WebService({
                endPoint: `CompanyProfile/FetchCompanyByName?SearchContent=${searchContent}`,
                method: "GET",
                dispatch,
            }).then(res => res.length > 0 ? setSearchedCompanyData(res): setSearchedCompanyData([]));
        }else{
            setSearchedCompanyData([]);
        }
    }

    var columns = [
        {
            Text: "Company Name",
            Value: "CompanyName",
        },
        {
            Text: "",
            render: (dr) => (
                <div className="d-flex justify-content-end">
                    <Link to={`${userType=='customer' ? 'User/':''}Login?company=${dr.Code}`}>
                        <button
                            className="btn btn-primary"
                            id="loginbtn"
                        >
                            Login&nbsp;{">>"}
                        </button>
                    </Link>
                </div>
            ),
          },
    ]

    return (
      <Modal size="lg" centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {userType !== null
              ? "Search your company name and login here"
              : "Who are you?"}
          </Modal.Title>
        </Modal.Header>

        <div className="p-3">
          {userType === null ? (
            // Show selection options initially
            <div className=" d-flex  flex-column flex-md-row text-center gap-2 my-5">
              
              <Button
                onClick={() => handleUserSelection("employee")}
                className="d-block  nav-underline"
              >
                I am an employee of a company
              </Button>
              <Button
                onClick={() => handleUserSelection("customer")}
                className="d-block  nav-underline"
              >
                I am a customer of a company
              </Button>
              <Button
                onClick={() => handleUserSelection("no_company")}
                className="d-block  nav-underline"
              >
                I do not belong to any company
              </Button>
            </div>
          ) : (
            // Show search input if Employee or Customer
            <>
              <div className="row">
                <div className="col-12">
                  <div className="position-relative">
                    <InputText
                      className="form-control pr-2"
                      style={{ paddingRight: "45px" }}
                      placeholder="Company Name"
                      setValue={(val) => setSearchContent(val)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          searchCompany();
                        }
                      }}
                    />
                    <ActionButton
                      className="position-absolute"
                      style={{ top: "0px", right: "0px" }}
                      onClick={searchCompany}
                      IconName="Search"
                      id={`btnSearch`}
                    />
                  </div>
                </div>
              </div>

              {searchedCompanyData.length > 0 ? (
                <TableComponent
                  columns={columns}
                  data={searchedCompanyData}
                  IsAddButtonVisible={false}
                  isExcelExportButtonVisible={false}
                  isSearchRequired={false}
                />
              ) : (
                <NoRecordTemplate
                  headerValue={"Search Your Company"}
                  imageUrl={StandardConst.imageNoRecordsFound}
                />
              )}
            </>
          )}
        </div>
      </Modal>
    );
};

export default forwardRef(LoginByCompanyName);
