import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  TextField,
  Typography,
  Avatar,
  Paper,
  Stack,
  Container,
  InputAdornment,
  Card,
  IconButton,
  useTheme,
  ThemeProvider,
  createTheme,
  CssBaseline,
  useMediaQuery,
  Drawer,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import InputBase from "@mui/material/InputBase";
import AppBar from "@mui/material/AppBar";
import { alpha, styled, width } from "@mui/system";
import {
  AttachFile,
  CancelRounded,
  Check,
  Close,
  DarkMode,
  DoneAll,
  GetApp,
  KeyboardDoubleArrowDownRounded,
  LightMode,
  Mail,
  Menu,
  Search,
  SearchRounded,
  Send,
  SendAndArchive,
  SettingsSharp,
} from "@mui/icons-material";
import { WebService } from "../../Services/WebService";
import { StandardConst } from "../../Services/StandardConst";
import { useSelector } from "react-redux";
import ActionButton from "../../Services/ActionButton";
import BreadcrumbsComponent from "../../Services/BreadcrumbsComponent";
import Button from "react-bootstrap/esm/Button";
import { Form, FormAutoCompleteDropdownCard } from "../Form";
import Col from "react-bootstrap/esm/Col";
import { json, useLocation } from "react-router-dom";
import { lastIndexOf } from "underscore";
import theme from "../../Services/WsMuiTheme";
import { grey } from "@mui/material/colors";
import { WSErrorAlert } from "../../Services/WSAlert";
import FileViewerModal from "./ImageViewer";

const StyledChatContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  height: "90vh",
  gap: 2,
  padding: 0,
  backgroundColor: theme.palette.mode === "dark" ? "#1a1a1a" : "#f5f5f5",
  [theme.breakpoints.down("md")]: {
    padding: theme.spacing(1),
    height: "calc(100vh - 70px)",
  },
}));

const SidePanel = styled(Paper)(({ theme }) => ({
  width: "300px",
  padding:0,
  display: "flex",
  flexDirection: "column",
  borderRadius:0,
  gap: theme.spacing(2),
  backgroundColor: theme.palette.mode === "dark" ? "#2d2d2d" : "#ffffff",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

const ChatArea = styled(Paper)(({ theme }) => ({
  flex: 1,
  padding: 0,
  display: "flex",
  flexDirection: "column",
  borderRadius:0,
  gap: theme.spacing(0),
  backgroundColor: theme.palette.mode === "dark" ? "#2d2d2d" : "#ffffff",
}));



const ChatUI = () => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [mobileOpen, setMobileOpen] = useState(false);
  const [mode, setMode] = useState("light");
  const [EmployeesAndUsers, setEmployeesAndUsers] = useState([]);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const EmployeeId = useSelector((s) => s.auth.LoggedUser ?? {});
  const UserId = useSelector((s) => s.auth.LoggedCustomer ?? {});
  const [filteredEmployeesAndUsers, setFilteredEmployeesAndUsers] = useState(
    []
  );
  const messagesEndRef = useRef(null);
  const [attachment, setAttachment] = useState(null);
  const MasterpageName = "Chat System";
  const [LoadingSearch, setLoadingSearch] = useState(false);
  const [loading, setLoading] = useState(false); // for fetching more chats

  const CompanyId = useSelector((s) => s.auth.CompanyInfo?.CompanyId ?? 0);
  const [UnreadMessages, setUnreadMessages] = useState([]);
  const [LastChatId, setLastChatId] = useState();
  const chatContainerRef = useRef(null);
  const [showFetchMore, setShowFetchMore] = useState(false);
  const [attachmentPreview, setAttachmentPreview] = useState(null);
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const listRef = useRef(null);

  useEffect(()=>{
    if(location?.state?.ToEmployeeId)
      {
        setSelectedEmployee(location.state.ToEmployeeId);
        BringLastChats();
      }
      if(location?.state?.User)
      {
        setSelectedEmployee(location.state.User);
        BringLastChats();
      }
  },[])

  const scrollToBottom = () => {
    setTimeout(() => {
      if (messagesEndRef.current) {
        messagesEndRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
      }
    }, 500); // 2-second delay
  };
  

  const toggleTheme = () => {
    setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
  };
  const SearchEmployeesAndUsers = async (values) => {

    setLoadingSearch(true);
    WebService({
      endPoint: `chat/SearchEmployeeAndUser?search=${values}`,
    }).then((r) => {     
      const formattedResults = r.map((item) => ({
        EmployeeId: item.Source == "employee" ? item?.Id : null,
        UserId: item.Source == "user" ? item?.Id : null,
        heading: item?.FullName,
        FullName: item?.FullName,
        Designation: item?.Designation,
        Description: item?.Email,
        ProfileImage: item?.ProfileImage,
        avatar:`${StandardConst.apiBaseUrl}/uploads/${item.ProfileImage}`,
      }));
      setEmployeesAndUsers(formattedResults);
    });
    setLoadingSearch(false);
  };
  const [bData] = useState([
    {
      title: MasterpageName,
      hrefLink: "#",
    },
    {
      title: " ",
      hrefLink: "#",
    },
  ]);

  const handleScroll = () => {
    const bottom =
      chatContainerRef.current.scrollHeight ===
      chatContainerRef.current.scrollTop +
        chatContainerRef.current.clientHeight;
    if (!bottom) {
      // If user is at the top, show "Show More" button
      setShowFetchMore(true);
    }
  };

  // Fetch more messages
  const fetchMoreMessages = async () => {
    setLoading(true);
    const oldermessages = await WebService({
      endPoint: `chat/getLastChats?selectedEmployeeId=${selectedEmployee?.EmployeeId || null}&selectedUserId=${selectedEmployee?.UserId || null}&offset=${messages.length}`,
    });

    const formattedMessages = oldermessages?.reverse()?.map((msg) => {
      const formattedMsg = {
        ChatId: msg.ChatId,
        Timestamp: new Date(msg.Timestamp).toLocaleString([], {
          dateStyle: "short",
          timeStyle: "short",
          hour12: true,
        }),
        sent: msg.FromEmployeeId === EmployeeId || msg.FromUserId === UserId,
        Status: msg.Status,
        Message: msg.Message,
        attachment:{
          name:msg.fileName,
          url:msg.fileUrl,
          size:msg.fileSize
        }
      };

      // Update unreadMessages state when message status is 'unread'
      if (msg.Status === "unread") {
        setUnreadMessages((prev) => [...prev, msg.ChatId]); // Use spread operator to add new ChatId
      }

      return formattedMsg;
    });

    setMessages((prev) => [...formattedMessages, ...prev]);
    setLoading(false);
    setShowFetchMore(false); // Hide "Show More" button after fetching
  };

  useEffect(() => {
    // Adding the scroll event listener to detect when the user is at the top
    const chatContainer = chatContainerRef.current;
    chatContainer?.addEventListener("scroll", handleScroll);

    return () => {
      chatContainer?.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const filterEmployeesAndUsers = (v) => {
    if (v.EmployeeId || v.UserId || v.Id) {
      setFilteredEmployeesAndUsers((prev) => [...new Set([...prev, v])]);
      setSelectedEmployee(null);
      setSelectedEmployee(v);
    }
   
  };

  // useEffect(() => {
  //   messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  // }, [messages]);

  const BringLastChats = async () => {
    const result = await WebService({ 
      endPoint: `chat/getLastChats?selectedEmployeeId=${selectedEmployee?.EmployeeId || null}&selectedUserId=${selectedEmployee?.UserId || null}`,
    });
    const formattedMessages = result?.reverse()?.map((msg) => {
      const formattedMsg = {
        ChatId: msg.ChatId,
        Timestamp: new Date(msg.Timestamp).toLocaleString([], {
          dateStyle: "short",
          timeStyle: "short",
          hour12: true,
        }),
        sent: msg.FromEmployeeId === EmployeeId || msg.FromUserId === UserId,
        Status: msg.Status,
        Message: msg.Message,
        ...(msg.Chatattachmentid && {
          attachment: {
            name: msg.fileName,
            url: msg.fileUrl,
            size: msg.fileSize,
          },
        }),
      };

      // Update unreadMessages state when message status is 'unread'
      if (msg.Status === "unread") {
        setUnreadMessages((prev) => [...prev, msg.ChatId]); // Use spread operator to add new ChatId
      }

      return formattedMsg;
    });

    setMessages(formattedMessages);
    // ReceiveMessages(messages[0]?.ChatId);
    setLastChatId(result[0]?.ChatId);
    scrollToBottom()
  };
  useEffect(() => {
    if (selectedEmployee) {
      BringLastChats();
    }
  }, [selectedEmployee]);

  const SendMessage = async () => {
    if (!newMessage?.trim() && !attachment) {
      return;
  }
  
  const tempMessage = {
    ChatId: null, 
    Message: newMessage,
    sent: true, 
    Status: "sending", 
    Timestamp: new Date().toLocaleString([], {
      dateStyle: "short",
      timeStyle: "short",
      hour12: true,
    }),
    attachment: attachment ? {
      name: attachment?.name,
      url: null, 
      tempurl: URL.createObjectURL(attachment),
      size: attachment.size,
      type: 'image'
    } : null
  };

  setMessages([...messages, tempMessage]);

  let fileDetails;
  if (attachment) {
    fileDetails = await WebService({
      endPoint: "upload/File?fileSize=2",
      body: attachment,
      isFile: true,
    }).catch((err)=>{
      WSErrorAlert('',err)
      console.log("Error uploading file:", err);
      return;
    })
  }
  console.log(fileDetails,attachment)
  if (!fileDetails && attachment) {
    setAttachment(null)
    const failedMessage = {
      ...tempMessage,
      Status: "failed",
    };

    setMessages([...messages, failedMessage]);

    return;  // ✅ Stop execution if file upload failed
  }
  
    setNewMessage(""); 
  
    scrollToBottom()
  
    try {
      let values = {
        FromEmployeeId: EmployeeId == 0 ? null : EmployeeId,
        FromUserId: UserId == 0 ? null : UserId,
        ToEmployeeId: selectedEmployee?.EmployeeId,
        ToUserId: selectedEmployee?.UserId,
        Message: newMessage,
        CompanyId: EmployeeId,
        Timestamp: new Date().toISOString(),
        Status: "unread",
      };
    let response = await WebService({
      endPoint: `CommonUtility/Chat/`,
      body: values,
    });
    
    if(fileDetails)
    {
      await WebService({
        endPoint: `CommonUtility/Chatattachments/`,
        body: {
          ChatId: response,
          fileName: attachment.name,
          fileUrl: fileDetails.filename,
          fileType: fileDetails.mimetype,
          fileSize: fileDetails.size,
          uploadedAt: new Date().toISOString(),
        }
      })
    }
    
  
      const finalMessage = {
        ChatId: response,
        Message: newMessage,
        sent: true,
        status: "sent", 
        Timestamp: new Date().toLocaleString([], {
          dateStyle: "short",
          timeStyle: "short",
          hour12: true,
        }),
        attachment: attachment ? {
          attachment,
          name: attachment.name,
          url:  fileDetails.filename,
          tempurl:null,
          size: fileDetails.size,
          type: 'image'
        } : null
      };
      setAttachment(null)
      setMessages((prevMessages) =>
        prevMessages.map((msg) =>
          msg === tempMessage ? finalMessage : msg
        )
      );
  
      setUnreadMessages((prev) => [...prev, response]);
  
    } catch (error) {
      console.error("Failed to send message:", error);
  
      const failedMessage = {
        ...tempMessage,
        Status: "failed",
      };
  
      setMessages((prevMessages) =>
        prevMessages.map((msg) =>
          msg === tempMessage ? failedMessage : msg
        )
      );
    }
    removeAttachment()
  };

  const getChatters = async () => {
    try {
      const data = await WebService({
        endPoint: `chat/getPersonsExstingChatters?EmployeeId=${EmployeeId}&UserId=${UserId}`,
      });
      const formatedData = data.map((item) => {
        return {
          EmployeeId: item.UserType === "E" ? item.UserId : null,
          UserId: item.UserType === "U" ? item.UserId : null,
          EmployeeFullName: item.UserType === "E" ? item.UserName : null,
          UserFullName: item.UserType === "U" ? item.UserName : null,
          Email: item.UserEmail,
          UnreadMessageCount: item.UnreadMessageCount,
          ProfileImage: item.UserProfileImage,
         
        };
      });

      setFilteredEmployeesAndUsers(formatedData);
    } catch (error) {}
  };
  useEffect(() => {
    if (EmployeeId || UserId) {
      getChatters();
    }
  }, [EmployeeId,UserId]);
  useEffect(() => {
    if (selectedEmployee) {
      let lastMessageId = LastChatId;
      const fetchMessages = async () => {
        try {
          const values = {
            ToEmployeeId: EmployeeId,
            ToUserId: UserId,
            FromEmployeeId: selectedEmployee.EmployeeId,
            FromUserId: selectedEmployee.UserId,
            LastMessageId: lastMessageId,
            StatuscheckIds: UnreadMessages,
          };

          const data = await WebService({
            endPoint: `chat/getLetestMessage`,
            body: values,
          });

          if (!data) return;

          const newData = data?.ChatterListAndCount || [];
          const formattedData = newData.map((item) => {
            return {
              EmployeeId: item.UserType === "E" ? item.UserId : null,
              UserId: item.UserType === "U" ? item.UserId : null,
              EmployeeFullName: item.UserType === "E" ? item.UserName : null,
              UserFullName: item.UserType === "U" ? item.UserName : null,
              Email: item.UserEmail,
              UnreadMessageCount: item.UnreadMessageCount,
              ProfileImage: item.ProfileImage,
            };
          });
          setFilteredEmployeesAndUsers((prevData) => {
            if (!prevData) return formattedData; // Initial assignment
          
            // Create a map from prevData for quick lookups
            const prevMap = new Map(prevData.map(item => [item.UserId || item.EmployeeId, item]));
          
            // Merge formattedData, ensuring the latest unread message count is kept
            formattedData.forEach(item => {
              const id = item.UserId || item.EmployeeId;
              if (prevMap.has(id)) {
                // Update UnreadMessageCount to the latest value
                prevMap.set(id, { ...prevMap.get(id), ...item });
              } else {
                // Add new entry
                prevMap.set(id, item);
              }
            });
          
            // Convert map to array and sort by UnreadMessageCount (highest first)
            return Array.from(prevMap.values()).sort((a, b) => b.UnreadMessageCount - a.UnreadMessageCount);
          });
          
          
        
          const newMessages = Array.isArray(data.newmsg) ? data.newmsg : [];
          const updatedStatusIds = Array.isArray(data.status)
            ? data.status
            : [];

          const formattedNewMessages = newMessages.map((msg) => ({
            ChatId: msg.ChatId,
            Timestamp: new Date(msg.Timestamp).toLocaleString([], {
              dateStyle: "short",
              timeStyle: "short",
              hour12: true,
            }),
            sent: false,
            Message: msg.Message,
            ...(msg.Chatattachmentid && {
              attachment: {
                name: msg.fileName,
                url: msg.fileUrl,
                size: msg.fileSize,
              },
            }),
           
          }));

          setMessages((prevMessages) => {
            // Filter out duplicates based on ChatId
            const existingIds = new Set(prevMessages.map((msg) => msg.ChatId));

            const uniqueNewMessages = formattedNewMessages.filter(
              (msg) => !existingIds.has(msg.ChatId)
            );

            setUnreadMessages((prev) =>
              prev.filter((id) => !updatedStatusIds.includes(id))
            );

            const updatedMessages = prevMessages.map((msg) => {
              if (updatedStatusIds.includes(msg.ChatId)) {
                return { ...msg, Status: "read" };
              }

              return msg;
            });
            if (uniqueNewMessages.length > 0) {
              scrollToBottom()
            }
            return [...updatedMessages, ...uniqueNewMessages];
          })
          

          // Update the latest message ID
          lastMessageId = newMessages[0]?.ChatId || lastMessageId;
        } catch (error) {
          console.error("Error receiving messages:", error);
        }
      };

      const intervalId = setInterval(fetchMessages, 10000);

      return () => clearInterval(intervalId); // Cleanup interval on unmount
    }
  

  }, [messages, UnreadMessages, EmployeeId, UserId, selectedEmployee]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  console.log(filteredEmployeesAndUsers)
  const contactsList = (
    <Box
      sx={{
        height: "100%",
        maxHeight: "500px",
        overflowY: "auto",
        overflowX: "hidden",
      }}
      className='py-2'
    >

      <Stack spacing={2}>
      {filteredEmployeesAndUsers
  .sort((a, b) => new Date(b.UnreadMessageCount) - new Date(a.UnreadMessageCount)) // Sort by timestamp (most recent first)
  .map((item) => (
  
    <Box
      key={item.EmployeeId}
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 2,
        cursor: "pointer",
        "&:hover": { backgroundColor: theme.palette.action.hover },
      }}
      className='px-2'
      title={`${item?.EmployeeFullName || item?.UserFullName || item?.FullName} (${item?.Email || "No Email"})`}
        onClick={() => {
        isMobile && handleDrawerToggle();
        setSelectedEmployee(null);
        setSelectedEmployee(item);
      }}
    >
      <Avatar
        src={`${StandardConst.apiBaseUrl}/uploads/${item?.ProfileImage}`}
        alt={item.name}
        sx={{ width: 50, height: 50 }}
      />
      <Box sx={{ width: "100%" }}>
        <Typography
          variant="subtitle1"
          className="d-flex flex-row justify-content-between font-weight-bold w-100"
        >
          <span className={mode === "dark" ? "text-white" : "text-dark"}>
            {item?.EmployeeFullName || item?.UserFullName || item?.FullName}

          </span>

          {item.UnreadMessageCount > 0 && (
            <span
              className="text-white rounded-full inline-flex my-auto text-center justify-center text-small items-center"
              style={{
                width: "20px",
                height: "20px",
                lineHeight: "20px",
                borderRadius: "50%",
                fontSize: "small",
                backgroundColor: "#d5ac32",
              }}
            >
              {item.UnreadMessageCount}
            </span>
          )}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {item.Email}
        </Typography>
      </Box>
    </Box>
  ))}

      </Stack>

    </Box>
  );

  const handleAttachmentChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setAttachment(file);
      if (file.type.startsWith("image/")) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setAttachmentPreview(reader.result);
        };
        reader.readAsDataURL(file);
      } else {
        setAttachmentPreview(file.name);
      }
    }
  };
  
  const removeAttachment = () => {
    setAttachment(null);
    setAttachmentPreview(null);
  }

    // Function to handle option click
    const handleSelect = (item) => {
      filterEmployeesAndUsers(item);
      setIsOpen(false); // Close the list after selection
    };
  
    // Click outside handler
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (listRef.current && !listRef.current.contains(event.target)) {
          setIsOpen(false);
        }
      };
  
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);
  
    const Search = styled("div")(({ theme }) => ({
      position: "relative",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: alpha(theme.palette.common.white, 0.15),
      "&:hover": {
        backgroundColor: grey,
      },
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: "100%",
      height: "30px",
      minheight: "30px",
    
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(3),
        width: "auto",
      },
    }));
    
 
    const StyledInputBase = styled(InputBase)(({ theme }) => ({
      color: "inherit",
      "& .MuiInputBase-input": {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(3)})`,
        transition: theme.transitions.create("width"),
        fontSize: ".8rem",
        width: "100%",
        border: "none",
        [theme.breakpoints.up("md")]: {
          width: "20ch",
        },
      },
    }));

const inputRef = useRef(null);
// useEffect(() => {
//   if (inputRef.current) {
//     inputRef.current.focus();
//   }
//   if(searchQuery)
//   {
//     SearchEmployeesAndUsers()
//   }
// }, [searchQuery]); // Keep focus when searchQuery updates

useEffect(() => {

  const delayDebounce = setTimeout(() => {
    if (searchQuery.trim() !== "") {
      SearchEmployeesAndUsers(searchQuery);
    }
  }, 500); 

  return () => clearTimeout(delayDebounce);
}, [searchQuery]);


const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));



  return (
    <>
      <ThemeProvider theme={theme}>
        <Container fluid className="base-container rounded-2">
          {" "}
          <div className="d-flex justify-content-between bg-white align-items-center rounded-3">
            <div className="">
              <h5 className="ms-4 mt-2">{MasterpageName}</h5>
              <div className="ms-4">
                <BreadcrumbsComponent bData={bData}></BreadcrumbsComponent>
              </div>
            </div>
          </div>
          <AppBar position="static" color="primary" elevation={1}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
              className="px-4 my-1 h-75"
            >
              {/* Mobile Menu Icon */}
              {isMobile && (
                <IconButton
                  color="inherit"
                  edge="start"
                  onClick={handleDrawerToggle}
                  sx={{ mr: 2 }}
                >
                  <Menu />
                </IconButton>
              )}

              {/* Search Section (Search Input + Button) */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexGrow: 1,
                }}
              >
                <span className="d-flex flex-row col-5">
                  <div className="position-relative d-flex align-items-center w-100">
                    <span style={{backgroundColor: mode === "dark" ? "" : "#3184fd",borderRadius: "5px"}} className="d-flex flex-row align-items-center px-1 w-100">
                    <SearchRounded sx={{ fontSize: 20 }}/>
                    <input
                      type="text"
                      placeholder="Search for a contact to start chatting"
                      onChange={(e) =>{e.target.value!='' && setSearchQuery(e.target.value)}}
                      onFocus={() => setIsOpen(true)}
                      style={{
                        width: "100%", // Ensure full width
                        backgroundColor: mode === "dark" ? "" : "#3184fd",
                        border: "none",
                        outline: "none",
                        color: "white", // Adjust text color if needed
                        height:'25px'
                      }}
                      className=""
                    />
                  </span>
                    {EmployeesAndUsers.length > 0 && isOpen && (
                      <ul
                        ref={listRef}
                        className="list-group position-absolute w-100 shadow mt-1 bg-white rounded overflow-auto z-3"
                        style={{
                          maxHeight: "250px",
                          top: "100%", // Ensures it appears below the input
                          left: 0,
                          zIndex: 10, // Ensures it's above other elements
                        }}
                      >
                          {LoadingSearch && (
                      <li className=" mt-1">Loading...</li>
                            )}
                        {EmployeesAndUsers.map((item, index) => (
                          <li
                            key={index}
                            onClick={() => {
                              filterEmployeesAndUsers(item);
                              setIsOpen(false);
                            }}
                            className="list-group-item list-group-item-action d-flex align-items-center gap-3 p-3 border"
                            style={{ cursor: "pointer" }}
                          >
                            <Avatar
                              src={`${StandardConst.apiBaseUrl}/uploads/${item?.ProfileImage}`}
                              alt={item.FullName}
                              className="rounded-circle img-fluid"
                              style={{ width: "50px", height: "50px" }}
                            />
                            <div className="d-flex flex-column">
                              <span className="fw-semibold text-dark">
                                {item.FullName}
                              </span>
                              <span className="text-muted small">
                                {item.Designation}
                              </span>
                            </div>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                  {/* <ActionButton
                    onClick={() => SearchEmployeesAndUsers()}
                    IconName="Search"
                    IconColor="white"
                    IconTooltip="Search"
                    sx={{ marginLeft: "10px" }}
                  /> */}
                </span>
              </div>

              {/* Dark Mode Button (Separated) */}
              {/* <IconButton onClick={toggleTheme} color="inherit">
                {mode === "dark" ? <LightMode /> : <DarkMode />}
              </IconButton> */}
            </div>
          </AppBar>
          <StyledChatContainer>
            {isMobile ? (
              <Drawer
                variant="temporary"
                anchor="left"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{ keepMounted: true }}
                sx={{
                  "& .MuiDrawer-paper": { width: "100%" },
                  borderRadius: 0,
                }}
              >
                <Box sx={{}}>
                <Box
      sx={{
        height: "100%",
        maxHeight: "500px",
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      <Stack spacing={2}>
      {filteredEmployeesAndUsers
  .sort((a, b) => new Date(b.UnreadMessageCount) - new Date(a.UnreadMessageCount)) // Sort by timestamp (most recent first)
  .map((item) => (
    <Box
      key={item.EmployeeId}
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 2,
        cursor: "pointer",
        p: 1,
        "&:hover": { backgroundColor: theme.palette.action.hover },
      }}
      onClick={() => {
        isMobile && handleDrawerToggle();
        setSelectedEmployee(null);
        setSelectedEmployee(item);
      }}
    >
      <Avatar
        src={`${StandardConst.images}/uploads/${item?.ProfileImage}`}
        alt={item.name}
        sx={{ width: 50, height: 50 }}
      />
      <Box sx={{ width: "100%" }}>
        <Typography
          variant="subtitle1"
          className="d-flex flex-row justify-content-between font-weight-bold w-100"
        >
          <span className={mode === "dark" ? "text-white" : "text-dark"}>
            {item?.EmployeeFullName || item?.UserFullName || item?.FullName}
          </span>

          {item.UnreadMessageCount > 0 && (
            <span
              className="text-white rounded-full inline-flex my-auto text-center justify-center text-small items-center"
              style={{
                width: "20px",
                height: "20px",
                lineHeight: "20px",
                borderRadius: "50%",
                fontSize: "small",
                backgroundColor: "#d5ac32",
              }}
            >
              {item.UnreadMessageCount}
            </span>
          )}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {item.Email}
        </Typography>
      </Box>
    </Box>
  ))}

      </Stack>

    </Box>
                </Box>
              </Drawer>
            ) : (
              <SidePanel elevation={0}>{contactsList}</SidePanel>
            )}

            <ChatArea elevation={1}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  mb: 2,
                }}
                className="px-4 py-1"
              >
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                  <Avatar
                    src={`${StandardConst.apiBaseUrl}/uploads/${selectedEmployee?.ProfileImage}`}
                    alt={
                      selectedEmployee?.EmployeeFullNameFullName ||
                      selectedEmployee?.UserFullName ||
                      selectedEmployee?.FullName
                    }
                  />
                  <Typography variant="h6">
                    {selectedEmployee?.EmployeeFullName ||
                      selectedEmployee?.UserFullName ||
                      selectedEmployee?.FullName}
                  </Typography>
                </Box>
                <span>
                  <IconButton
                    onClick={() => scrollToBottom()}
                    title="scroll to end of chat"
                  >
                    <KeyboardDoubleArrowDownRounded />
                  </IconButton>
                  <IconButton aria-label="settings">
                    <SettingsSharp />
                  </IconButton>
                </span>
              </div>
              <hr style={{ margin: 0 }} />
              {selectedEmployee ? (
                <>
                  <div
                    style={{
                      flex: 1,
                      overflow: "auto",
                      overflowY: "scroll",
                      display: "flex",
                      flexDirection: "column",
                      gap: 1,
                      mb: 2,
                      p: 1,
                    }}
                    ref={chatContainerRef}
                  >
                    {/* {showFetchMore && ( */}
                    {messages.length > 0 && (
                      <Button
                        variant="outlined"
                        onClick={fetchMoreMessages}
                        sx={{ mb: 2 }}
                      >
                        {loading ? "Loading..." : "Show More"}
                      </Button>
                    )}
                    {/* )} */}
                    {messages.map((message) => (
                      <ChatMessage message={message} />
                    ))}
                    <div ref={messagesEndRef} style={{ margin: "40px" }} />
                  </div>

                  <div>
                    <div>
                      {attachmentPreview && (
                        <Box className="d-flex align-items-center justify-content-between bg-light rounded p-1 mb-1">
                          {typeof attachmentPreview === "string" &&
                          attachmentPreview.startsWith("data:image") ? (
                            <>
                            <img
                              src={attachmentPreview}
                              alt="Attachment Preview"
                              style={{
                                width: "50px",
                                height: "50px",
                                objectFit: "cover",
                                borderRadius: "4px",
                                
                              }}
                            />
                            </>

                          ) : (
                            <Typography variant="body2" className="ms-2">
                              {attachmentPreview}
                            </Typography>
                          )}
                          <IconButton
                            onClick={removeAttachment}
                            size="small"
                            className="ms-2"
                          >
                            <Close fontSize="small" />
                          </IconButton>
                        </Box>
                      )}
                    </div>
                    <div
                      sx={{ display: "flex", gap: "1" }}
                      className="bg-primary d-flex flex-row px-2 py-1"
                    >
                      <input
                        fullWidth
                        placeholder="Type a message"
                        value={newMessage}
                        onChange={(e) => setNewMessage(e.target.value)}
                        onKeyPress={(e) => e.key === "Enter" && SendMessage()}
                        style={{ marginRight: 1 }}
                        className=" border-0"
                      />
                      <IconButton
                        component="label"
                        aria-label="attach file"
                        className="text-white me-2"
                        title="attachments"
                      >
                        <AttachFile />
                        <input
                          type="file"
                          hidden
                          onChange={handleAttachmentChange}
                          accept="*"
                        />
                      </IconButton>
                      <IconButton
                        onClick={SendMessage}
                        aria-label="send message"
                        className="text-white"
                      >
                        <Send />
                      </IconButton>
                    </div>
                  </div>
                </>
              ) : (
                <div className="d-flex  flex-row justify-content-center ">
                  Select a contact to start chatting
                </div>
              )}
            </ChatArea>
          </StyledChatContainer>
        </Container>
      </ThemeProvider>
    </>
  );
};



const MessageContainer = styled(Box)(({ sent }) => ({
  display: "flex",
  justifyContent: sent ? "flex-end" : "flex-start",
  marginBottom: "8px",
}));

const MessageBubble = styled(Box)(({ sent }) => ({
  backgroundColor: sent ? "#DCF8C6" : "#fff",
  color: "#000",
  padding: "8px 12px",
  borderRadius: "8px",
  maxWidth: "75%",
  wordBreak: "break-word",
  display: "flex",
  flexDirection: "column",
  position: "relative",
  boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
}));

const AttachmentPreview = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "5px",
  marginTop: "5px",
});

const DownloadButton = styled(IconButton)({
  padding: "4px",
});

const ChatMessage = ({ message }) => {
  const imageRegex = /\.(jpg|jpeg|png|gif|bmp|webp|svg)$/i;
  const isImage = message.attachment?.name
    ? imageRegex.test(message.attachment.name)
    : false;
  const isDocument = message.attachment && !isImage;

  const handleDownload = () => {
    const url = `${StandardConst.apiBaseUrl}/uploads/${message.attachment.url}`;
    window.open(url, "_blank");
  };

  return (
    <MessageContainer sent={message.sent}>
      <MessageBubble sent={message.sent}>
        {/* Message Text */}

        {/* Attachment Preview */}
        {message.attachment && (
          <AttachmentPreview>
            {isImage && (
              <>
              {/* <img
                src={
                  message.attachment.tempurl
                    ? message.attachment.tempurl
                    : `${StandardConst.apiBaseUrl}/uploads/${message.attachment.url}`
                }
                alt="Attachment"
                style={{ width: "200px", borderRadius: "8px" }}
              /> */}
              <FileViewerModal fileUrl={`${StandardConst.apiBaseUrl}/uploads/${message.attachment.url}`}/>
              </>
            )}

            {isDocument && (
              <Box>
                <Typography variant="body2" fontWeight="bold">
                  {message.attachment.name}
                </Typography>
                <Typography variant="caption">
                  {message.attachment.size}
                </Typography>
              </Box>
            )}

            {/* Download Button for Documents */}
            {isDocument && (
              <DownloadButton onClick={handleDownload} size="small">
                <GetApp fontSize="small" />
              </DownloadButton>
            )}
          </AttachmentPreview>
        )}

        {message.Message && (
          <Typography variant="body1">
            {" "}
           {message.Message}
          </Typography>
        )}
        {/* Timestamp & Read Status */}
        <Typography
          variant="caption"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            mt: 0.5,
            opacity: 0.7,
          }}
        >
          {new Date(message.Timestamp).toLocaleTimeString([], {
            month: "short",
            day: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          })}
          {message.sent &&
            (message.Status === "read" ? (
              <DoneAll
                fontSize="small"
                sx={{ color: "blue", marginLeft: "5px" }}
              />
            ) : message.Status === "failed" ? (
              <CancelRounded color="error" sx={{ marginLeft: "5px" }} />
            ) : message.Status === "sending" ? (
              <CircularProgress size='10px' sx={{marginLeft:'5px'}} />
            ) : (
              <Check fontSize="small" sx={{ marginLeft: "5px" }} />
            ))}
        </Typography>
      </MessageBubble>
    </MessageContainer>
  );
};


export default ChatUI;
