import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import Auth from "./Auth";
import { Outlet, Route, Routes, HashRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./Services/Store";
import LogIn from "./Component/LogIn/LogInComponent";
import UserLogIn from "./Component/Users/LogIn/LogInComponent";
import ApplyLeave from "./Component/Leave/Applyleave";
import AllApplyleavelist from "./Component/Leave/AllApplyleavelist";
import PerformanceComponent from "./Component/Performance/PerformanceComponent";
import PaySlipComponent from "./Component/PaySlip/PaySlipComponent";
import DashboardComponent from "./Component/Dashboard/DashboardComponent";
import ActionCenter from "./Component/Dashboard/ActionCenter";
import ChangePasswordComponent from "./Component/LogIn/ChangePasswordComponent";
import JobFunction from "./Component/Static/JobFunctions";
import Document from "./Component/Static/DocumentType";
import KeyRating from "./Component/Static/KRA";
import LeaveType from "./Component/Static/LeaveType";
import Permission from "./Component/Static/Permission";
import Roles from "./Component/Static/Roles";
import Status from "./Component/Static/StatusType";
import Project from "./Component/Static/Project";
import Task from "./Component/Static/TaskCategory";
import TimeSheet from "./Component/TimeSheet/TimesheetComponent";
import Profile from "./Component/ProfileComponent/EditProfileComponent";
import LeaveBalance from "./Component/Leave/leaveBalanceComponent";
import RegistrationApproval from "./Component/Approval/RegistrationApproval";
import TimesheetReport from "./Component/TimeSheet/TimesheetReport";
import MenuMaster from "./Component/Static/MenuMaster";
import UserRole from "./Component/Static/UserRole";
import HolidayList from "./Component/Registration/HolidayListComponent";
import Holiday from "./Component/Static/Holiday";
import RolePermission from "./Component/Static/RolePermission";
import MenuRole from "./Component/Static/MenuRole";
import TaxMaster from "./Component/Static/TaxMaster";
import TaskType from "./Component/Static/TaskType";
import SalaryComponent from "./Component/PaySlip/SalaryComponent/SalaryComponent";
import PayslipTemplate from "./Component/PaySlip/PayslipTemplate/PayslipTemplate";
import Registration from "./Component/RegistrationComponent";
import SalaryPackage from "./Component/PaySlip/Package/SalaryPackage";
import Error from "./Component/Errorlog/ErrorComponent";
import Errorlog from "./Component/Errorlog/ErrorlogComponent";
import StaticPage from "./Component/Static/StaticPage";
import StaticComponents from "./Component/Static/StaticComponents";
import ComponentDetails from "./Component/Static/ComponentDetails";
import Officelocation from "./Component/Static/OfficelocationComponent";
import Company from "./Component/Company/CompanyProfile";
import CompanyProfileList from "./Component/Company/CompanyProfileList";
import Team from "./Component/TimeSheet/Team";
import ARTypeMaster from "./Component/Static/ARTypeMaster";
import SalaryARInvoice from "./Component/PaySlip/SalaryComponent/EmployeeSalaryARInvoice";
import AttendanceReport from "./Component/Attendance/AttendanceReport";
import BankDetails from "./Component/Static/BankDetails";
import EmployeeIdCard from "./Component/EmployeeIdCard/EmployeeIdCardComponent";
import ProjectRoleMaster from "./Component/Static/StaticProjectRoleComponent";
import CompanyRegistration from "./Component/Company/CompanyRegistration";
import Qualification from "./Component/Static/StaticQualificationMaster";
import ConfigurationMaster from "./Component/Static/ConfigurationMaster";
import ConfigurationComponent from "./Component/Static/ConfigurationComponent";
import LeaveApproval from "./Component/Leave/LeaveApproval";
import AssignTeam from "./Component/ProjectManagement/AssignTeam";
import { Loader } from "./Services/Loader";
import SalaryTemplateComponent from "./Component/PaySlip/Template/SalaryTemplateComponents";
import SalaryTemplate from "./Component/PaySlip/Template/SalaryTemplate";
import Customer from "./Component/Customer/Customer";
import TeamDetailComponent from "./Component/TimeSheet/TeamDetails";
import LeaveReport from "./Component/Leave/LeaveReport";
import SalarySummary from "./Component/PaySlip/Reports/SalarySummary";
import Subscription from "./Component/Subscription/Subscription";
import Designation from "./Component/Static/Designation";
import EmployeeJobFunction from "./Component/JobFunctions/EmployeeJobFunction";
import EmployeeKRA from "./Component/JobFunctions/EmployeeKRA";
import EmployeeListComponent from "./Component/Employee/EmployeeListComponent";
import CompanyDashboard from "./Component/Dashboard/Company/CompanyDashboard";
import HumanResourceDashboard from "./Component/Dashboard/HumanResource/HumanResourceDashboard";
import EmployeeDashboard from "./Component/Dashboard/Employee/EmployeeDashboard";
import PaymentConfirmation from "./Component/Subscription/PaymentConfirmation";
import DashboardCommonComponent from "./Component/Dashboard/DashboardCommonComponent";
import User from "./Component/Customer/User";
import ReviewTimeSheetByCustomer from "./Component/TimeSheet/ReviewTimeSheetByCustomer";
import CommonNotificationComponent from "./Component/Notification/CommonNotificationComponent";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";
import CreateNotificationComponent from "./Component/Notification/CreateNotificationComponent";
import CustomerInvoiceComponent from "./Component/Invoice/CustomerInvoiceComponent";
import SuperAdminNotificationComponent from "./Component/Notification/SuperAdminNotificationComponent";
import InvoiceSettingsComponent from "./Component/Invoice/InvoiceSettingsComponent";
import InvoiceComponent from "./Component/Invoice/CustomerInvoice/InvoiceComponent";
import VisitorCheckInOutComponent from "./Component/Visitor/VisitorCheckInOutComponent";
import WalkinVisitorRegistrationComponent from "./Component/Visitor/WalkinVisitorRegistrationComponent";
import ScheduleVisitorRegistrationComponent from "./Component/Visitor/ScheduleVisitorRegistrationComponent";
import VisitorSelfCheckInComponent from "./Component/Visitor/VisitorSelfCheckInComponent";
// import VisitorMaster from "./Component/Visitor/VisitorMaster";
import Department from "./Component/Static/Department";

import VisitorTypeMaster from "./Component/Visitor/VisitorTypeMaster";
import InvoiceAddComponent from "./Component/Invoice/CustomerInvoice/InvoiceAddComponent";
import InvoiceSettingsListComponent from "./Component/Invoice/InvoiceSettings/InvoiceSettingsListComponent";
import InvoiceTaxMaster from "./Component/Static/InvoiceTaxMaster";
import CustomerInvoicePayment from "./Component/Invoice/InvoicePayment/CustomerInvoicePayment";
import CustomerProfile from "./Component/Customer/CustomerProfile";
import BadgeTemplates from "./Component/BadgeTemplates/BadgeTemplates";
import GenerateInvoice from "./Component/Invoice/GenerateInvoice";
import AssignPermissions from "./Component/Static/AssignPermissions";
import ManageEvents from "./Component/Event/ManageEvents";
import QuickCompanyRegistration from "./Component/Company/QuickCompanyRegistration";
import ManageContactsForOfficeLocation from "./Component/Static/ManageContactsForOfficeLocation";
import ManageEventsAttributes from "./Component/Event/ManageEventsAttributes";
import ManageEventCoordinators from "./Component/Event/ManageEventCoordinators";
import ManageAccessLevel from "./Component/AcessLabel/ManageAccessLabel";
import AccessGroup from "./Component/AccessGroup/AccessGroup";
import AssignAccessLevels from "./Component/AssignAccessLabels/AssignAccessLabels";
import AccessLevelType from "./Component/AcessLabel/AccessLevelType";
import AssignAccessToUsers from "./Component/AssignAccessLabels/AssignAccessToUsers";
import ValidateBadge from "./Component/Visitor/ValidateBadge";
import { FaceDetector } from "./components/FaceDetector";
import { FaceCapture } from "./components/FaceCapture";
import { EmployeeAttendanceWithFaceScanning } from "./components/EmployeeAttendanceWithFaceScanning";
import ManageAssetTypes from "./Component/AssetManagement/ManageAssetTypes";
import ManageAssets from "./Component/AssetManagement/ManageAssets";
import AddAssetInformation from "./Component/AssetManagement/AddAssetInformation";
import AttendanceSummaryReport from "./Component/Attendance/AttendanceSummaryReport";
import AssetReport from "./Component/AssetManagement/AssetAssignmentReport";
import VehicleRegistration from "./Component/VehicleManagement/VehicleRegistration";
import ManageVehicles from "./Component/VehicleManagement/ManageVehicles";
import VehicleMaintenanceAndRepairs from "./Component/VehicleManagement/VehicleMaintenanceAndRepairs";
import AssetLocation from "./Component/AssetManagement/AssetLocation";
import AssetCustomFields from "./Component/AssetManagement/AssetCustomFields";
import ManageAgents from "./Component/Helpdesk/ManageAgents";
import ManageTickets from "./Component/Helpdesk/ManageTickets";
import TicketCategories from "./Component/Helpdesk/TicketCategories";
import VehicleDriverAssignment from "./Component/VehicleManagement/VehicleDriverAssignment";
import VehicleCustomFields from "./Component/VehicleManagement/VehicleCustomFields";
import VehicleInVehicleOut from "./Component/VehicleManagement/VehicleInVehicleOut";
import AssetDashboard from "./Component/AssetManagement/AssetDashboard";
import AssignTicketCategory from "./Component/Helpdesk/AssignTicketCategory";
import ManageAttach from "./Component/Helpdesk/ManageAttach";
import VehicleDashboard from "./Component/VehicleManagement/VehicleDashboard";
import ViewTicket from "./Component/Helpdesk/ViewTicket";
import ManageSubscription from "./Component/Subscription/ManageSubscription";
import SubscriptionType from "./Component/Subscription/SubscriptionType";
import AddEditManageSubscription from "./Component/Subscription/AddEditManageSubscription";
import BankDetailsCustomFields from "./Component/Static/BankDetailsCustomFields";
import EmployeeCustomFields from "./Component/ProfileComponent/EmployeeCustomFields";
import EmployeePayslipCustomFields from "./Component/Static/EmployeePayslipCustomFields";
import EmployeeAttendanceWithQRCodeScanning from "./Component/Attendance/EmployeeAttendanceWithQRCodeScanning";
import BadgeTemplatesCardDisplay from "./Component/BadgeTemplates/BadgeTemplatesCardDisplay";
import SendMail from "./Component/QuickSendMailToUsers/SendMail";
import JobApplicantForm from "./Component/Recuritment/JobApplicantForm";
import JobListing from "./Component/Recuritment/JobListing";
import ManageUserType from "./Component/Users/UserType/ManageUserType";
import FixInterview from "./Component/Interview/FixInterview";
import ScheduledInterview from "./Component/Interview/ScheduledInterview";
import NonCompanyUserLogin from "./Component/LogIn/NonCompanyUserLogin";
import AddEditJobPosting from "./Component/Recruitment/AddEditJobPosting";
import ApplicantsList from "./Component/Recuritment/ApplicantsList";
import JobApplicantProfile from "./Component/Recuritment/JobApplicantProfile";
import ManageInterviewType from "./Component/Recuritment/ManageInterviewType";
import RecruiterDashboard from "./Component/Recuritment/RecruiterDashboard";
import AddEditEmpSalaryPackage from "./Component/PaySlip/Package/AddEditEmpSalaryPackage";
import TimesheetVsLeaveReport from "./Component/Attendance/TimesheetVsLeaveReport";
import AutomaticPayslipGeneration from "./Component/PaySlip/PayslipTemplate/AutomaticPayslipGeneration";
import ManageDocument from "./Component/Document_Management/ManageDocument";
import ManageMailTemplate from "./Component/MailTemplate/ManageMailTemplate";
import ManageGroup from "./Component/ManageGroup/ManageGroups";
import SendDocumentInternalUser from "./Component/Document_Management/SendDocumentInternalUser";
import SendDocumentExternalUser from "./Component/Document_Management/SendDocumentExternalUser";
import MyDocuments from "./Component/Document_Management/MyDocument";
import DocumentHistory from "./Component/Document_Management/DocumentHistory";
import UploadSignedDocument from "./Component/Document_Management/UploadSignedDocument";
import ProfileSearch from "./Component/Recuritment/ProfileSearch";
import JobView from "./Component/Recuritment/JobView";
import ManageProductTickets from "./Component/HelpdeskProduct/ManageProductTickets";
import ProductTicketCategories from "./Component/HelpdeskProduct/ProductTicketCategories";
import ManageProductAgents from "./Component/HelpdeskProduct/ManageProductAgents";
import ManageSkills from "./Component/Recuritment/ManageSkills";
import SupportHome from "./Component/HelpdeskProduct/SuportHome";
import AgentTicketCategory from "./Component/Helpdesk/AssignTicketCategory";
import AddEditMailTemplate from "./Component/MailTemplate/AddEditMailTemplate";
import SendMailToUsers from "./Component/QuickSendMailToUsers/SendMailToUsers";
import Chatpage from "./Component/Chat/Chatpage";
import ChatUI from "./Component/Chat/ChatUI";
import EmailUnsubscribe from "./Component/ManageSubscribers/EmailUnsubscribe";
import CloneMailTemplate from "./Component/MailTemplate/CloneMailTemplate";

const root = ReactDOM.createRoot(document.getElementById("root"));
TimeAgo.addDefaultLocale(en);
root.render(
  <>
    <HashRouter>
      <Provider store={store}>
        <Routes>
          <Route path="/" element={<CompanyRegistration />} />
          <Route path="JobApplicantForm" element={<JobApplicantForm />} />
          {/* same funcnality ka route login ke bad bhi hai kyuki baha mujhe company or loggedIn spacific job list krni hai */}
          <Route path="AllJobs" element={<JobListing />} />
          <Route path="QuickCompanyRegistration" element={<QuickCompanyRegistration />} />
          <Route path="NonCompanyUserLogin" element={<NonCompanyUserLogin />} />
          <Route path="job/:jobName/:JobId" element={<JobView />} />
          <Route path='Unsubscribe' element={<EmailUnsubscribe/>}/>

          <Route path='SignDocument' element={<UploadSignedDocument/>}/>
          <Route path="" element={<Auth />}>
            <Route path="User/Login" element={<UserLogIn />} />
            <Route path="Login" element={<LogIn />} />
            <Route
              path="Visitor/Self/CheckIn"
              element={<VisitorSelfCheckInComponent />}
            />
            <Route path="Register" element={<Registration />} />
            <Route path="HolidayList" element={<HolidayList />} />
            <Route
              path="PaymentConfirmation"
              element={<PaymentConfirmation />}
            />
          </Route>
          <Route path="" element={<App />}>
            <Route path="Payslip" element={<PaySlipComponent />} />
            <Route
              path="ApprovedEmployee"
              element={<EmployeeListComponent />}
            />
            <Route path="AutomaticPayslipGeneration" element={<AutomaticPayslipGeneration />} />
            <Route path="AddEditEmpSalaryPackage" element={<AddEditEmpSalaryPackage />} />
            <Route path="EditApplicantProfile" element={<JobApplicantForm />} />
            <Route path="RecruiterDashboard" element={<RecruiterDashboard />} />
            <Route path="ManageInterviewType" element={<ManageInterviewType />} />
            <Route path="JobApplicantProfile" element={<JobApplicantProfile />} />
            <Route path="ApplicantsList" element={<ApplicantsList />} />
            <Route path="AddEditJobPosting" element={<AddEditJobPosting />} />
            {/* same funcnality ka route without login me bhi hai kyuki baha mujhe All companies ke job ki list dikhani hai or apply bhi krwana hai */}
            <Route path="MyJobList" element={<JobListing />} />
            <Route path="SendMail" element={<SendMail />} />
            <Route path="BadgeTemplatesCardDisplay" element={<BadgeTemplatesCardDisplay />} />
            <Route path="EmployeeAttendanceWithQRCodeScanning" element={<EmployeeAttendanceWithQRCodeScanning />} />
            <Route path="EmployeePayslipCustomFields" element={<EmployeePayslipCustomFields />} />
            <Route path="EmployeeCustomFields" element={<EmployeeCustomFields />} />
            <Route path="BankDetailsCustomFields" element={<BankDetailsCustomFields />} />
            <Route path="VehicleInVehicleOut" element={<VehicleInVehicleOut />} />
            <Route path="VehicleDashboard" element={<VehicleDashboard />} />
            <Route path="VehicleCustomFields" element={<VehicleCustomFields />} />
            <Route path="AttendanceSummaryReport" element={<AttendanceSummaryReport />} />
            <Route path="VehicleDriverAssignment" element={<VehicleDriverAssignment />} />
            <Route path="VehicleMaintenanceAndRepairs" element={<VehicleMaintenanceAndRepairs />} />
            <Route path="ManageVehicles" element={<ManageVehicles />} />
            <Route path="VehicleRegistration" element={<VehicleRegistration />} />
            <Route path="EmployeeAttendanceWithFaceScanning" element={<EmployeeAttendanceWithFaceScanning />} />
            <Route path="FaceDetector" element={<FaceDetector />} />
            <Route path="FaceCapture" element={<FaceCapture />} />
            <Route path="ValidateBadge" element={<ValidateBadge />} />
            <Route path="ActionCenter" element={<ActionCenter />} />
            <Route path="Dashboard1" element={<DashboardComponent />} />
            <Route path="Dashboard" element={<DashboardCommonComponent />} />
            <Route path="Performance" element={<PerformanceComponent />} />
            <Route path="AllApplyleavelist" element={<AllApplyleavelist />} />
            <Route path="applyleave" element={<ApplyLeave />} />
            <Route path="JobFunction" element={<JobFunction />} />
            <Route path="Document" element={<Document />} />
            <Route path="KeyRating" element={<KeyRating />} />
            <Route path="LeaveType" element={<LeaveType />} />
            <Route path="Permission" element={<Permission />} />
            <Route path="Roles" element={<Roles />} />
            <Route path="ManagePermissions/:roleId" element={<AssignPermissions />} />
            <Route path="Status" element={<Status />} />
            <Route path="Task" element={<Task />} />
            <Route path="Project" element={<Project />} />
            <Route path="LeaveBalance" element={<LeaveBalance />} />
            <Route path="MenuMaster" element={<MenuMaster />} />
            <Route path="UserRole" element={<UserRole />} />
            <Route path="Holiday" element={<Holiday />} />
            <Route path="RolePermission" element={<RolePermission />} />
            <Route path="MenuRole" element={<MenuRole />} />
            <Route path="TaxMaster" element={<TaxMaster />} />
            <Route path="Template" element={<SalaryTemplate />} />
            <Route path="PayslipTemplate" element={<PayslipTemplate />} />
            <Route path="TaskType" element={<TaskType />} />
            <Route path="SalaryComponent" element={<SalaryComponent />} />
            <Route path="Errorlog" element={<Errorlog />} />
            <Route path="StaticPage" element={<StaticPage />} />
            <Route path="StaticComponents" element={<StaticComponents />} />
            <Route path="Officelocation" element={<Officelocation />} />
            <Route path="ManageContactsForOfficeLocation" element={<ManageContactsForOfficeLocation />} />
            <Route path="ComponentDetails" element={<ComponentDetails />} />
            <Route path="Company" element={<Company />} />
            <Route path="CompanyProfileList" element={<CompanyProfileList />} />
            <Route path="AttendanceReport" element={<AttendanceReport />} />
            <Route path="TimesheetVsLeaveReport" element={<TimesheetVsLeaveReport />} />
            <Route path="ARTypeMaster" element={<ARTypeMaster />} />
            <Route path="SalaryARInvoice" element={<SalaryARInvoice />} />
            <Route path="BankDetails" element={<BankDetails />} />
            <Route path="EmployeeIdCard" element={<EmployeeIdCard />} />
            <Route path="ProjectRoleMaster" element={<ProjectRoleMaster />} />
            <Route path="Qualification" element={<Qualification />} />
            <Route path="LeaveApproval" element={<LeaveApproval />} />
            <Route path="Customer" element={<Customer />} />
            <Route path="CustomerUser" element={<User />} />
            <Route path="LeaveReport" element={<LeaveReport />} />
            <Route path="SalarySummary" element={<SalarySummary />} />
            <Route path="Subscription" element={<Subscription />} />
            <Route path="Designation" element={<Designation />} />
            <Route path="CompanyDashboard" element={<CompanyDashboard />} />
            <Route path="HRDashboard" element={<HumanResourceDashboard />} />
            <Route path="EmployeeDashboard" element={<EmployeeDashboard />} />
            {/* <Route path="VisitorMaster" element={<VisitorMaster />} /> */}
            <Route path="Department" element={<Department />} />
            <Route path="Visitor/VisitorType" element={<VisitorTypeMaster />} />
            <Route path="InvoiceTaxMaster" element={<InvoiceTaxMaster />} />
            <Route path="ManageBadgeTemplates" element={<BadgeTemplates/>} />
            <Route
              path="CreateNotification"
              element={<CreateNotificationComponent />}
            />
            <Route
              path="SuperAdminNotification"
              element={<SuperAdminNotificationComponent />}
            />
            <Route
              path="CustomerInvoice"
              element={<CustomerInvoiceComponent />}
            />
            <Route
              path="GenerateInvoice"
              element={<GenerateInvoice />}
            />
            <Route
              path="InvoiceSettings"
              element={<InvoiceSettingsListComponent />}
            />
            <Route
              path="AddInvoiceSettings"
              element={<InvoiceSettingsComponent />}
            />
            <Route path="Invoice" element={<InvoiceComponent />} />
            <Route path="AddInvoice" element={<InvoiceAddComponent />} />
            <Route path="InvoicePayment" element={<CustomerInvoicePayment />} />
            <Route
              path="EmployeeJobFunction"
              element={<EmployeeJobFunction />}
            />
            <Route path="EmployeeKRA" element={<EmployeeKRA />} />
            <Route
              path="SalaryTemplateComponent"
              element={<SalaryTemplateComponent />}
            />
            <Route path="AssignTeam" element={<AssignTeam />} />
            <Route
              path="ConfigurationMaster"
              element={<ConfigurationMaster />}
            />
            <Route
              path="ConfigurationComponent"
              element={<ConfigurationComponent />}
            />
            <Route
              path="RegistrationApproval"
              element={<RegistrationApproval />}
            />
            <Route path="TimeSheet" element={<Outlet />}>
              <Route path="Entry" element={<TimeSheet />} />
              <Route path="Report" element={<TimesheetReport />} />
              <Route path="Team" element={<Team />} />
              <Route path="Team/Details" element={<TeamDetailComponent />} />
              <Route path="Customer" element={<Outlet />}>
                <Route path="Review" element={<ReviewTimeSheetByCustomer />} />
              </Route>
            </Route>
            <Route path="SalaryPackage" element={<SalaryPackage />} />
            
            <Route path="Common" element={<Outlet />}>
              <Route path="Error" element={<Error />} />
              <Route
                path="ChangePassword"
                element={<ChangePasswordComponent />}
              />
              <Route path="Profile" element={<Profile />} />
              <Route
                path="Notification"
                element={<CommonNotificationComponent />}
              />
              <Route path="CustomerProfile" element={<CustomerProfile />} />
            </Route>
            <Route path="Visitor" element={<Outlet />}>
              <Route
                path="Registration"
                element={<WalkinVisitorRegistrationComponent />}
              />
              <Route
                path="Invite"
                element={<ScheduleVisitorRegistrationComponent />}
              />
              <Route path="CheckIn" element={<VisitorCheckInOutComponent />} />
            </Route>
            <Route path="ManageEvents" element={<ManageEvents />} />
            <Route path="ManageEventCoordinators" element={<ManageEventCoordinators />} />
            <Route path="ManageEventAttributes" element={<ManageEventsAttributes />} />
            <Route path="EventVisitorsList" element={<VisitorCheckInOutComponent />} />
            {/* <Route path="EventVisitorsList" element={<EventVisitorsList />} />  */}

            <Route path="ManageAccessLevel" element={<ManageAccessLevel />} />
            <Route path="AccessGroup" element={<AccessGroup />} />
            <Route path="AssignAccessLevels" element={<AssignAccessLevels />} />
            <Route path="AccessLevelType" element={<AccessLevelType />} />
            <Route path="AssignAccessToUsers" element={<AssignAccessToUsers />} />
            <Route path="ManageAssetTypes" element={<ManageAssetTypes />} />
            <Route path="ManageAssets" element={<ManageAssets />} />
            <Route path="AddAssetInformation" element={<AddAssetInformation />} />
            <Route path="AssetReport" element={<AssetReport />} />
            <Route path="AssetLocation" element={<AssetLocation />} />
            <Route path="AssetCustomFields" element={<AssetCustomFields />} />
            <Route path="ManageAgents" element={<ManageAgents />} />
            <Route path="ManageTickets" element={<ManageTickets />} />
            <Route path="TicketCategories" element={<TicketCategories />} />
            <Route path="AssetDashboard" element={<AssetDashboard />} />
            <Route path="AssignTicketCategory" element={<AssignTicketCategory />} />
            <Route path="ManageAttach" element={<ManageAttach />} />
            <Route path="ViewTicket" element={<ViewTicket />} />
            <Route path="ManageSubscription" element={<ManageSubscription />} />
            <Route path="SubscriptionType" element={<SubscriptionType />} />
            <Route path="ManageUserType" element={<ManageUserType />} />
            <Route path="AddEditManageSubscription" element={<AddEditManageSubscription />} />
            <Route path="FixInterview" element={<FixInterview />} />
            <Route path="ScheduledInterview" element={<ScheduledInterview />} />
            <Route path='ManageDocument' element={<ManageDocument/>}/>
            <Route path="SendDocumentInternalUser/:documentId" element={<SendDocumentInternalUser/>} />
            <Route path="SendDocumentExternalUser/:documentId" element={<SendDocumentExternalUser/>} />
            <Route path="ManageMail" element={<ManageMailTemplate/>} />
            <Route path='ManageGroups' element={<ManageGroup/>}/>
            <Route path='MyDocument' element={<MyDocuments/>}/>
            <Route path='DocumentHistory' element={<DocumentHistory/>}/>
            <Route path="ProfileSearch" element={<ProfileSearch />} />
            <Route path="Support" element={<SupportHome/>}/>
            <Route path="ProductSupport" element={<ManageProductTickets/>}/>
            <Route path="ProductTicketCategories" element={<ProductTicketCategories/>}/>
            <Route path="ProductTicketAgents" element={<ManageProductAgents/>}/>
            <Route path="ManageSkills" element={<ManageSkills/>}/>
            <Route path="AppplyJob/:jobName/:JobId" element={<JobView/>}/>
            <Route path="AddEditMailTemplate" element={<AddEditMailTemplate/>}/>
            <Route path="SendMailToUser" element={<SendMailToUsers/>}/>    
            <Route path="chat" element={<Chatpage/>}/>
            <Route path="newchat" element={<ChatUI />} />
            <Route path='CloneMailTemplate' element={<CloneMailTemplate/>}/>
          </Route>
        </Routes>
        <Loader />
      </Provider>
    </HashRouter>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
