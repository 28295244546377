import React, { useRef, useState, useEffect } from "react";
import QrReader from 'react-qr-scanner';
import Box from "@mui/material/Box";
import { useDispatch } from "react-redux";
import { WebService } from "../../Services/WebService";
import { Card } from "@mui/material";
import BreadcrumbsComponent from "../../Services/BreadcrumbsComponent";
import { StandardConst } from "../../Services/StandardConst";
import { WSErrorAlert, WSSuccessAlert } from "../../Services/WSAlert";
import { PageInfo } from "../PageInfo";
import ActionButton from "../../Services/ActionButton";
import { format } from "date-fns/esm";
import AlertComponent from "../../Services/AlertComponent";

const EmployeeAttendanceWithQRCodeScanning = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [location, setLocation] = useState(null);
  const [locationError, setLocationError] = useState(null);
  const [scannerActive, setScannerActive] = useState(false);
  const alertRef = useRef(null);

  PageInfo({ pageTitle: "Employee Attendance By QR-Code" });

  const MasterPageName = "Employee Attendance By QR-Code";
  const [bData, setBData] = React.useState([
    {
      title: "Home",
      hrefLink: "/",
      visible: false,
    },
    {
      title: "Employee Attendance By QR-Code",
      hrefLink: "#",
    },
  ]);

  console.log(location)
  // Request location on component load

  async function AskLocation()
  {

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLocation({ latitude, longitude });
          setScannerActive(true); // Enable scanner after getting location
        },
        (error) => {
          setLocationError("Please grant location permission to capture location for checkin/checkout.");
          setScannerActive(true); // Disable scanner if location access is denied
        }
      );
    } else {
      setLocationError("Geolocation is not supported by your browser or device and checkin/checkout will be done without location");
      setScannerActive(true); // Disable scanner if geolocation is not supported
    }
  }
  useEffect(() => {
  AskLocation();
  }, []);


  // Handle QR scan
  const handleScan = (data) => {
    if (data && !loading && scannerActive) {
      setScannerActive(false); // Disable scanner during API call
      checkInEmployeeByQRCode(data.text);
    }
  };

  const handleError = (err) => {
    console.error(err);
  };

  // Handle employee check-in by QR code
  const checkInEmployeeByQRCode = async (scannedQRData) => {
    setLoading(true);
    const arrQRCodeData = scannedQRData.split(',');

    if (scannedQRData !== "" && arrQRCodeData.length > 0) {
      const Employee = arrQRCodeData[0];
      const Guid = arrQRCodeData[1];

      if (Employee === StandardConst.UserType.Employee && Guid.length > 32) {
        const TodayDateTime = format(new Date(), "yyyy-MM-dd HH:mm:ss");
        const objBody = { TodayDateTime: TodayDateTime, Guid: Guid, ...location };

        await WebService({
          dispatch,
          endPoint: "EmployeeIdCard/EmployeeCheckInByQRCode",
          body: objBody,
          method: "POST",
        }).then((res) => {
          if (res.Message === StandardConst.EmployeeCheckInCheckOutSuccessMsg.CheckInMsg) {
            WSSuccessAlert("success", res.EmployeeName + " Employee Successfully Checked In.");
          } else if (res.Message === StandardConst.EmployeeCheckInCheckOutSuccessMsg.CheckOutMsg) {
            WSSuccessAlert("success", res.EmployeeName + " Employee Successfully Checked Out.");
          } else if (res.Message === StandardConst.EmployeeCheckInCheckOutSuccessMsg.EmployeeErrMsg) {
            WSErrorAlert("Error", "Not a valid employee please contact your HR.");
          } else {
            WSErrorAlert("Error", "Something went wrong please contact your admin.");
          }
        });
      } else {
        WSErrorAlert("Invalid Badge", "This badge is not a valid badge. Please contact reception desk.");
      }
    } else {
      WSErrorAlert("Invalid QR Code", "The scanned QR code is invalid.");
    }
    setLoading(false);
    setScannerActive(true); // Re-enable scanner after API call
  };

  // Refresh component
  const refreshComponent = () => {
    window.location.reload();
  };

  return (
    <>
      <AlertComponent
        ref={alertRef}
        confirmEvent={(v) => checkInEmployeeByQRCode(v)}
      />
      <div className="base-container container-fluid">
        <Box sx={{ pt: 1, width: 1, height: 80 }}>
          <h5 className="ms-4 mt-2">{MasterPageName}</h5>
          <div className="ms-4">
            <BreadcrumbsComponent bData={bData}></BreadcrumbsComponent>
          </div>
        </Box>
        <div className="p-3 primary-bg-color"></div>
 
        <div className="base-margin-x base-margin-2y mb-2">
          <div className="row mb-4 justify-content-center mt-2">
            <Card sx={{ maxWidth: 300, backgroundColor: "#fbfbfb" }}>
              
{       locationError &&     <div className="alert alert-danger">{locationError}</div>
}             
                  {scannerActive && (
                    <QrReader
                      delay={500}
                      onError={handleError}
                      onScan={handleScan}
                      style={{ width: "100%" }}
                    />
                  )
                }
                <span>
                  Scan the QR CODE of the badge
                  <ActionButton
                    onClick={refreshComponent}
                    IconName="Loop"
                    IconTooltip="Refresh"
                    id="Refresh"
                  />
                </span>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployeeAttendanceWithQRCodeScanning;